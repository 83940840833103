import React, { useState, useEffect, useRef } from 'react'

import {
  WrapContent,
  MiddleTextContent,
  IntroText,
  GreyBlock,
  H2,
  H3,
  P,
  PUL,
  PMSsustavIMG,
  FullWidthImg,
  ImgTitle,
  MiddleContent,
  GradientText,
  SideText,
  ButtonCTA,
  UnderButtonText,
  LeftColumn,
  TopText,
  LeftLink,
  BlueLine,
  WrapLeftContent
} from './styles'
import Img from 'gatsby-image'

import PMSsustav from '../../../assets/images/PMSsustav.png'
import Kokpit from '../../../assets/images/Kokpit.png'
import Kalendar from '../../../assets/images/Kalendar.png'
import Statistika from '../../../assets/images/Statistika.png'
import MobileApps from '../../../assets/images/MobileApps.png'
import RadarGroup from '../../../assets/images/RadarGroup.png'
import Integrations from '../../../assets/images/integrationsAuthority.png'
import Form from '../../AuthorityForm'

const links = [
  {
    id: 1,
    title: '5 razloga zašto vam je potreban cloud-based hotel PMS'
  },
  {
    id: 2,
    title: 'Ključne značajke hotelskog property management sustava'
  },
  {
    id: 3,
    title: 'Prednosti korištenja modernog PMS sustava za upravljanje hotelom'
  },
  {
    id: 4,
    title: 'Zašto je važno koristiti integrirani PMS, Channel Manager i Web Booking Engine?'
  },
  {
    id: 5,
    title: 'Koja je razlika između hotelskih on-premise i cloud-based property management sustava?'
  },
  {
    id: 6,
    title: 'Ulaganje u property management sustav: cijena, implementacija, onboarding i korisnička podrška'
  },
  {
    id: 7,
    title: 'Ključno prilikom odabira hotelskog PMS sustava'
  },
  {
    id: 8,
    title: 'Kako odabrati property management sustav za svoje hotelsko poslovanje?'
  },
  {
    id: 9,
    title: 'Zašto Rentlio PMS?'
  }
]

const Content = ({}) => {
  useEffect(() => {
    var links = document.querySelectorAll('.link')
    var sections = document.querySelectorAll('.sections')
    window.onscroll = function () {
      var current = 'home'
      sections.forEach((section) => {
        const sectionTop = section.offsetTop
        if (pageYOffset >= sectionTop - 200) {
          current = section.getAttribute('id')
        }
      })
      links.forEach((item) => {
        item.classList.remove('active')
        if (item.href.slice(-1) === current) {
          item.classList.add('active')
        } else {
          item.classList.remove('active')
        }
      })
    }
  }, [])

  const handleClick = (e) => {
    e.preventDefault()
    window.location = 'https://rentl.io/property-management-system'
  }
  return (
    <WrapContent>
      <LeftColumn>
        <WrapLeftContent>
          <BlueLine />
          <TopText>Teme unutar stranice</TopText>
          {links.map((link) => (
            <LeftLink key={link.id}>
              <a className="link" href={`#${link.id}`}>
                {link.title}
              </a>
            </LeftLink>
          ))}
        </WrapLeftContent>
      </LeftColumn>
      <MiddleTextContent>
        <IntroText>
          Hotelijerstvo se ubrzano mijenja zbog digitalne transformacije. Gosti traže potpuno digitalno iskustvo prije
          nego što uopće stignu u hotel. Većina ponuđača hotelskih property management sustava nudi stara, zastarjela i
          tradicionalna rješenja. Takvi naslijeđeni PMS sustavi kreirani su prije više od dvadeset godina i jednostavno
          ne mogu pratiti moderna očekivanja današnjih gostiju.
          <br />
          <br />
          <a href="https://rentl.io/hotel">Moderni property management sustavi</a>, s druge strane, pomažu hotelijerima
          automatizirati zadatke i procese kako bi se mogli usredotočiti na ono što rade najbolje – na pružanje iznimnih
          iskustava svojim gostima.
          <br />
          <br />U nastavku saznajte ključne značajke i funkcionalnosti hotelskog cloud-native PMS-a kako biste
          efikasnije upravljali hotelskim poslovanjem.
        </IntroText>
        <GreyBlock id="1" className="sections">
          <H2>5 razloga zašto vam je potreban cloud-based hotel PMS</H2>
          <H3>Smanjite troškove i iskoristite besplatne automatske nadogradnje</H3>
          <P>
            Brojni su troškovi povezani s korištenjem tradicionalnih on-premise sustava poput skupe hardverske opreme i
            fizičkog mjesta za pohranu podataka, visokih troškova održavanja, skupih prilagodbi, složenih integracija
            koje su često ograničene i još mnogih drugih, često skrivenih troškova.
          </P>
          <P>
            Svaka nadogradnja naslijeđenog on-premise sustava traje dugo i dodatan je trošak. S druge strane,
            <a href="https://rentl.io/hotel"> moderni cloud-based PMS sustavi</a> poznati su po brzoj implementaciji,
            minimalnim IT troškovima i troškovima održavanja, otvorenim API integracijama te besplatnim i automatskim
            nadogradnjama sustava. Većina pružatelja cloud-based usluga nudi modele cijena temeljene na pretplati, bez
            skrivenih naknada i troškova.
          </P>
          <P>
            Hotelijerima je model plaćanja temeljen na pretplati pristupačniji, posebno uzevši u obzir dodatne troškove
            implementacije i održavanja tradicionalnog legacy PMS hotelskog sustava.
          </P>
          <H3>Automatizacija zadataka i procesa</H3>
          <P>
            Još uvijek koristite zastarjeli hotelski sustav instaliran na računalu u vašem hotelu kako biste upravljali
            svojim poslovanjem? Ako je odgovor ”DA”, niste spremni za današnje brzo digitalno okruženje u kojem se
            hotelijeri natječu kako bi istovremeno privukli goste, zadržali kvalitetno osoblje, optimizirali prodaju i
            poboljšali kvalitetu iskustva gostiju.
          </P>
          <P>
            <a href="https://rentl.io/">Hotelski cloud-native property management sustav</a> automatizira sve aspekte
            vašeg hotelskog poslovanja funkcionalnostima poput online check-ina, mobilnog i web skenera dokumenata,
            automatske sinkronizacije cijena i dostupnosti na svim povezanim OTA kanalima, a to su tek neke od dostupnih
            funkcionalnosti i mogućnosti korištenja. Hotelska tehnologija brzo se razvija, a hotelijeri moraju razumjeti
            svoje potrebe i izazove kako bi pronašli odgovarajućeg tehnološkog partnera.
          </P>
          <H3>Pristup PMS-u bilo kada i na svim uređajima</H3>
          <P>
            Prilikom odabira hotelskog PMS sustava, provjerite imate li pristup sustavu na svim uređajima, uključujući
            stolna računala, tablete i pametne telefone. To će vam omogućiti da uvijek budete u toku sa svime što se
            događa s hotelskim poslovanjem. Moderan cloud PMS fleksibilan je i lako dostupan bilo kada, na svim
            uređajima. Bez obzira želite li provjeriti status rezervacija, rezervirate li sobu za posebnog gosta ili
            mijenjate i ažurirate najnovije cijene na prodajnim OTA kanalima – trebali biste sve to moći učiniti izravno
            sa svog pametnog telefona.
          </P>
          <P>
            <a href="https://app.rentl.io/registracija">
              Besplatno preuzmite mobilnu aplikaciju i otkrijte kako upravljati svojim hotelom uz mobitel!
            </a>
          </P>
          <H3>Donosite odluke temeljene na podacima</H3>
          <P>
            Analitika i statistika vrlo su važne prilikom donošenja strateških odluka. Cilj cjelovitog cloud PMS sustava
            nije samo pojednostaviti i automatizirati poslovanje hotela, već pratiti podatke o gostima i pomoći pri
            donošenju strateških odluka. Statistički podaci, poput stopa popunjenosti, podataka o gostima, broju noćenja
            i rezultata prodajnih kanala ključni su za udvostručenje prodajnih i marketinških aktivnosti kako biste
            privukli više gostiju. Podacima ćete postići svoje ciljeve fokusirajući se na aspekte ponude koji generiraju
            99% profita, a ne 1%. Ne zaboravite, prodaja smještaja i hrane generira 95% prihoda hotela.
          </P>
          <H3>Optimizacija izravnih i online rezervacija</H3>
          <P>
            Danas je sve online. Hoteli se oslanjaju na online kanale za generiranje rezervacija i prihoda. Dva su
            glavna načina za online rezervaciju:
          </P>
          <PUL>
            <ol>
              <li> OTA kanali</li>
              <li> Izravne rezervacije putem web stranice hotela</li>
            </ol>
          </PUL>
          <P>
            OTA kanali poput Booking.com-a i Expedia-e najčešći su online prodajni kanali. 
            <a href="https://rentl.io/channel-manager-hrvatska">
              Moderan hotelski property management sustav s integriranim channel managerom
            </a>{' '}
            ažurira cijene i dostupnost na svim kanalima istovremeno. Integracija hotelskog PMS-a i channel managera
            eliminira overbooking, preklapanje rezervacija i osigurava sinkronizaciju svih kanala, u bilo kojem
            trenutku.
          </P>
          <P>
            Izravne rezervacije hotelijeri ostvaruju i putem web stranice, instaliranjem widget-a na web stranici
            hotela, pod nazivom <a href="https://rentl.io/hotel-booking-engine">Web Booking Engine (WBE)</a>.
            Integrirani hotelski PMS sustav i Booking Engine omogućuju primitak izravnih rezervacija s vlastite web
            stranice direktno u PMS. Izravne rezervacije putem hotelskih web stranica danas su vrlo popularne jer
            hotelijeri tako izbjegavaju plaćanje visokih OTA provizija i ostvaruju veće prihode.
          </P>
          <P>
            <a href="https://app.rentl.io/registracija">
              Saznajte kako hoteli koriste Rentlijev integrirani Web Booking Engine za više izravnih rezervacija.
            </a>
          </P>
          <PMSsustavIMG>
            <img src={PMSsustav}></img>
          </PMSsustavIMG>
        </GreyBlock>
        <Form locale="hr" />
        <H2 id="2" className="sections">
          Ključne značajke hotelskog property management sustava
        </H2>
        <P>
          Prije odabira property management sustava, važno je razumjeti razliku između značajki i funkcionalnosti.
          Značajku zamislite kao skup specifičnih alata koje koristite za dovršenje zadatka. Funkcionalnosti su, s druge
          strane, ono što proizvod može učiniti kako bi riješio vaš jedinstveni poslovni problem.
        </P>
        <P>
          Budući da je svaki hotel specifičan, s jedinstvenim zahtjevima, važno je pronaći tehnološkog partnera koji vam
          može pružiti podršku tijekom cijelog procesa.
        </P>
        <P>
          Otkrijte ključne značajke Rentlio Property Management sustava i saznajte kako modernizirati svoje hotelsko
          poslovanje.
        </P>
        <H3>Glavni Dashboard</H3>
        <P>
          Glavna nadzorna ploča, odnosno Rentlio Dashboard pregled je svih dolazaka, odlazaka i boravaka unutar PMS-a.
          Mogućnost filtriranja i prilagodbe prema vlastitim potrebama uz traku za pretraživanje olakšava pronalaženje
          rezervacije i gosta u bilo kojem trenutku.
        </P>
        <FullWidthImg>
          <img src={Kokpit} />
        </FullWidthImg>
        <ImgTitle>Kokpit</ImgTitle>
        <H3>Kalendar</H3>
        <P>
          90-dnevni kalendarski prikaz olakšava pregled svih rezervacija, dostupnosti i cijena za svaku smještajnu
          jedinicu. Imate opciju kreiranja neograničenog broja smještajnih jedinica uz upravljanje cijenama i
          dostupnošću u bilo kojem trenutku. Sve promjene unutar PMS-a automatski će biti sinkronizirane i prikazane na
          web stranici i svim povezanim OTA kanalima. Jednostavna drag&select značajka olakšava upravljanje kalendarom i
          rezervaciju smještaja u bilo kojem trenutku. Više opcija prikaza omogućuje vam izmjene po želji.
        </P>
        <FullWidthImg>
          <img src={Kalendar} />
        </FullWidthImg>
        <ImgTitle>Kalendar</ImgTitle>
        <H3>Rezervacije i booking</H3>
        <P>
          Modul za rezervacije omogućuje pregled svih informacija o gostima na jednom mjestu, uključujući datum
          check-ina i check-outa, broj osoba, ukupnu cijenu i status naplate. Modul olakšava stvaranje pojedinačnih ili
          grupnih rezervacija, uređivanje i ažuriranje postojećih rezervacija izravno unutar PMS- a, mogućnost pregleda
          stanja plaćanja i dodavanja dodatnih troškova na račun. Izradite folije i podijelite račune na temelju želja
          svojih gostiju.
        </P>
        <H3>Statistika i analitika</H3>
        <P>
          Analizirajte i pratite prihode iz mjeseca u mjesec poput pregleda stope popunjenosti, rezervacija, izvješća o
          cijenama itd. Prilagodite izvještaje svojim potrebama i željama. Rentlio nudi više od 50 izvještaja kojima
          ćete pratiti sve o svom poslovanju i donositi pravovremene strateške odluke.
        </P>
        <FullWidthImg>
          <img src={Statistika} />
        </FullWidthImg>
        <ImgTitle>Statistika i analitika</ImgTitle>
        <H3>Naplata i računi</H3>
        <P>
          Pregled svih računa, plaćanja i storniranja na jednom mjestu. Eventualne nepodmirene račune i načine plaćanja
          po rezervaciji jednostavno ćete pratiti, uz mogućnost uređivanja i prilagodbe računa prema željama gosta.
        </P>
        <H3>Integracije</H3>
        <P>
          Uz potpuno integriran channel manager i web booking engine, Rentlio nudi mnoge integracije koje se mogu
          implementirati u nekoliko klikova. Otvorena API komunikacija hotelijerima olakšava integracije i povezivanje s
          njihovim omiljenim aplikacijama.
        </P>
        <FullWidthImg>
          <img src={Integrations} />
        </FullWidthImg>
        <ImgTitle>Rentlio Integracije</ImgTitle>
        <H3>Mobilna aplikacija</H3>
        <P>
          Mobilna aplikacija nudi većinu značajki dostupnih na desktop verziji i omogućava praćenje svega što se događa
          s vašim hotelskim poslovanjem. Možete pregledavati statuse rezervacija, ažurirati cijene i dostupnost po
          sobama, skenirati putovnice i osobne iskaznice za prijavu gostiju i još mnogo toga.
        </P>
        <P>
          <a href="https://app.rentl.io/registracija">
            Besplatno preuzmite Rentlio mobilnu aplikaciju i istražite njezin potencijal.
          </a>
        </P>
        <FullWidthImg>
          <img src={MobileApps} />
        </FullWidthImg>
        <ImgTitle>Mobilna aplikacija</ImgTitle>
        <GreyBlock>
          <H2 id="3" className="sections">
            Prednosti korištenja modernog PMS sustava za upravljanje hotelom
          </H2>
          <P>
            Danas više od 35% hotelijera još uvijek koristi olovku, papir i Excel tablice za upravljanje svojim
            objektima. Zamislite kako se vaš gost osjeća kad stigne i čeka voditelja recepcije koji zaisuje podatke na
            komad papira. Što oni misle? U kojem stoljeću živite?
          </P>
          <P>
            Korištenje modernog property management sustava za upravljanje hotelskim poslovanjem automatizira
            ponavljajuće zadatke poput ažuriranja cijena i dostupnosti, slanja personaliziranih e- mailova prije dolaska
            i nakon boravka, sigurna plaćanja i više. Integriran{' '}
            <a href="https://rentl.io/hotel-channel-manager">channel manager</a> omogućuje preuzimanje rezervacija s
            raznih OTA kanala i njihov pregled izravno unutar hotelskog PMS-a. S modernim PMS sustavom vaše osoblje
            troši manje vremena na administraciju, a više vremena na to da goste dočeka i osigura vrhunsko iskustvo.
          </P>
          <P>
            Danas je dostupno mnogo pružatelja usluge cloud-based property management sustava. Cijena PMS rješenja više
            nije visoka, a implementacija je mnogo jednostavnija.
          </P>
          <P>
            <a href="https://rentl.io/cijene">
              Izračunajte cijenu i uštedite implementacijom Rentlio Property Management sustava.
            </a>
          </P>
        </GreyBlock>
        <Form />
        <H2 id="4" className="sections">
          Zašto je važno koristiti integrirani PMS, Channel Manager i Web Booking Engine?
        </H2>
        <P>
          Većina pružatelja usluge nudi back-office funkcionalnosti PMS sustava bez web booking enginea i channel
          managera. U tom slučaju raspolažete osnovnim property management sustavom za koji su vam potrebne višestruke
          integracije kako biste upravljali cjelovitim hotelskim poslovanjem i primali online rezervacije.
          Funkcionalnosti poput booking engine-a i channel managera mogu biti vrlo skupe za implementaciju i dodatan su
          trošak.
        </P>
        <P>
          Mi u Rentliju vjerujemo da su channel manager i web booking engine temelj poslovanja svakog hotela, te bi
          stoga trebali biti u potpunosti integrirani i uključeni u PMS sustav. Rentlijev integrirani PMS, Booking
          Engine i Channel Manager uvijek su sinkronizirani, kako ne biste morali brinuti o različitim cijenama i
          statusima dostupnosti na OTA kanalima, što u potpunosti sprječava overbooking i preklapanje rezervacija.
        </P>
        <P>
          Uz Rentlio integrirani PMS, Booking Engine i Channel Manager, hotelijeri na raspolaganju imaju i pouzdanu
          podršku koji mogu kontaktirati ako nešto pođe po krivu.
        </P>
        <FullWidthImg style={{ width: '60%' }}>
          <img src={RadarGroup} />
        </FullWidthImg>
        <GreyBlock>
          <H2 id="5" className="sections">
            Koja je razlika između hotelskih on-premise i cloud-based property management sustava?
          </H2>
          <P>
            Na tržištu postoje stotine pružatelja usluga PMS-a, a hotelijeri moraju biti vrlo selektivni pri odabiru
            pravog rješenja. Prije nego što uopće počnete tražiti odgovarajući property management sustav, morate znati
            što tražite – tradicionalno on-premise ili cloud-based PMS rješenje.
          </P>
          <H3>Tradicionalni Property Management sustavi</H3>
          <P>
            Prvi on-premise property management sustav razvijen je u kasnim 90-im godinama u svrhu rješavanja
            jednostavnih zadataka na recepciji poput check-in i check-outa. Tijekom godina, naslijeđena rješenja donekle
            su se razvila i prilagodila se novim trendovima, ali većina značajki, korisničko sučelje te UX ostali su
            kakvi su bili inicijalno 90-ih. Povrh toga, on-premise rješenja su složena i izgrađena na starim
            tehnologijama, što ih čini teškima za prilagodbu. Visoki troškovi implementacije i održavanja, zastarjelo
            korisničko sučelje i nedostatak integracija preusmjerili su fokus hotelijera na istraživanje drugih opcija.
          </P>
          <H3>Cloud-based Property Management sustavi</H3>
          <P>
            Cloud-based property management sustav je moderno rješenje najnovije tehnologije. Intuitivno korisničko
            sučelje čini cloud-based property management sustav idealnim rješenjem za hotelsko poslovanje. Fleksibilnost
            prilagodbe temeljem vaših potreba i integracije s vašim odabranim integracijskim partnerom čini cloud-based
            PMS preferiranom opcijom.
          </P>
          <P>
            <a href="https://rentl.io/channel-manager-hrvatska">
              Saznajte kako Rentlio PMS, Channel Manager i Web Booking Engine pomažu hotelijerima diljem svijeta u
              poslovanju.
            </a>
          </P>
        </GreyBlock>
        <H2 id="6" className="sections">
          Ulaganje u property management sustav: cijena, implementacija, onboarding i korisnička podrška
        </H2>
        <H3>Cijena</H3>
        <P>
          Cijena PMS-a ovisi o brojnim čimbenicima, poput broja smještajnih jedinica, broja potrebnih integracija,
          složenosti operacija i kompleksnosti, potrebi za Booking Engine-om, razini podrške i mnogim drugim
          čimbenicima. Prosječna cijena property management sustava u 2023. iznosi 9 eura po smještajnoj jedinici. Za 9
          eura hotelijeri obično raspolažu samo back-office funkcionalnostima, bez dodatnih značajki i integracija.
          Možete uštedjeti mnogo implementacijom cloud-native rješenja s integriranim Web Booking Engine i Channel
          Manager funkcionalnostima. U tom slučaju, osigurat ćete si popust na integracije koje morate imati, a koje su
          danas ključne za svako hotelsko poslovanje.
        </P>
        <P>
          <a href="https://rentl.io/cijene">Izračunajte uštedu prelaskom na Rentlio.</a>
        </P>
        <H3>Implementacija</H3>
        <P>
          U usporedbi s on-premise sustavima, implementacija cloud-native PMS sustava može potrajati samo nekoliko dana.
          Trošak implementacije ovisi o pružatelju usluge i odabranom tehnološkom partneru, ali većina cloud-native
          implementacija uključena je u cijenu i besplatna. Vaš će hotel potrošiti manje na održavanje i IT troškove, u
          usporedbi s korištenjem tradicionalnih naslijeđenih legacy sustava.
        </P>
        <H3>Onboarding</H3>
        <P>
          Hotelijeri sve više žele ‘plug and play’ iskustvo. Mnogi pružatelji usluga PMS-a nude onboarding i edukaciju
          za čitavo hotelsko osoblje. Detaljna edukacija pomaže hotelskom osoblju i upravi ne samo kako koristiti
          ključne module, već i kako koristiti značajke koje nisu dio temeljnog PMS-a, ali čine veliku razliku.
        </P>
        <P>
          <a href="https://app.rentl.io/registracija">Prijavite se i isprobajte Rentlio PMS besplatno.</a>
        </P>
        <H3>Korisnička podrška</H3>
        <P>
          Imati pouzdanu dostupnu 24/7 podršku jedan je od najvažnijih (ako ne i najvažniji) aspekta prije odabira
          tehnološkog partnera. Zatražite podatke o radu podrške, poput podatka o radnom vremenu, prosječno vrijeme
          rješavanja zahtjeva, itd. Ovi podaci će pomoći kako biste bili sigurni koliko vremena je potrebno kako bi se
          eventualan problem riješio.
        </P>
        <FullWidthImg>
          <MiddleContent>
            <GradientText>99%</GradientText>
            <SideText>korisnika Rentlija ocjenjuje rad korisničke podrške s "Amazing"</SideText>
          </MiddleContent>
        </FullWidthImg>
        <GreyBlock>
          <H2 id="7" className="sections">
            Ključno prilikom odabira hotelskog PMS sustava
          </H2>
          <PUL className="greyedUL">
            <ul style={{ listStyleType: 'disc' }}>
              <li>Cijena varira o više čimbenika.</li>
              <li>
                Uvijek tražite intiuitivan cloud-native property management sustav koji je jednostavan za korištenje.
              </li>
              <li>Potražite načine kako biste smanjili IT i troškove održavanja.</li>
              <li>Odaberite “plug and play” pristup.</li>
              <li>Pobrinite se da vaše osoblje zna koristiti softver.</li>
              <li>Ne koristite PMS kojem nedostaje pouzdana podrška.</li>
            </ul>
          </PUL>
        </GreyBlock>
        <H2 id="8" className="sections">
          Kako odabrati property management sustav za svoje hotelsko poslovanje?
        </H2>
        <P>
          Ovdje su neka od pitanja koja možete postaviti potencijalnim tehnološkim partnerima bez obzira na veličinu
          vašeg hotela.
        </P>
        <PUL>
          <ul style={{ listStyleType: 'disc' }}>
            <li>Odabran PMS sustav je *samo* cloud ili je cloud-native rješenje? Velika razlika!</li>
            <li>Nude li se sveobuhvatne back-office funkcionalnosti?</li>
            <li>
              Kako izgleda prikaz kalendara? Nudi li se interaktivni dashboard s mnogo opcija i mogućnosti filtriranja?
            </li>
            <li>Korištenje sučelja je intuitivno i jednostavno je za korisnika?</li>
            <li>Može li se PMS sustavu pristupiti bilo kada, na bilo kojem uređaju?</li>
            <li>Koliko integracija je dostupno?</li>
            <li>Jesu li u PMS-u dostupna sveobuhvatna i pouzdana izvješća i precizna statistika?</li>
            <li>Channel Manager i Web Booking Engine su integrirani u PMS sustav?</li>
            <li>Dostupna je mogućnost personaliziranog onboardinga i pouzdana podrška?</li>
            <li>Koliko vremena je potrebno za implementaciju PMS-a?</li>
            <li>Postoji li besplatno probno razdoblje?</li>
          </ul>
        </PUL>
        <P>
          <a href="https://rentl.io/kontakt">Zatražite Rentlio demo i provjerite odgovara li vašim potrebama.</a>
        </P>
        <GreyBlock id="9" className="sections">
          <H2>Zašto Rentlio PMS?</H2>
          <P>
            Rentlio je moderan cloud-native property management sustav, namijenjen hotelima. Više tisuća klijenata u
            više od 40 zemalja upravlja svojim objektima koristeći upravo Rentlio PMS.
          </P>
          <P>
            Osnovan 2014. u Zadru, u Hrvatskoj, kako bi zamijenio staromodnu i zastarjelu hotelsku tehnologiju,
            pojednostavljuje i automatizira hotelske procese. U Rentliju vjerujemo u besprijekorno upravljanje hotelskim
            poslovanjem, kako bi se hotelijeri usredotočili na ono što rade najbolje – pružanje vrhunskih iskustava
            gostima.
          </P>
        </GreyBlock>
        <ButtonCTA onClick={(e) => handleClick(e)}>Saznajte više o Rentlio PMS-u</ButtonCTA>
        <UnderButtonText>Počnite koristiti svoj budući PMS, već danas!</UnderButtonText>
      </MiddleTextContent>
    </WrapContent>
  )
}

export default Content
