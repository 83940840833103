import React, { useState, useEffect, useRef } from 'react'

import {
  WrapBanner,
  Content,
  Title,
  Text,
  Button,
  BannerTextBlock,
  BannerImageBlock,
  BannerImage1,
  BannerImage2,
  BannerImage3
} from './styles'
import Img from 'gatsby-image'

import bannerImg1 from '../../../assets/images/bannerImg1.png'
import bannerImg2 from '../../../assets/images/bannerImg2.png'
import bannerImg3 from '../../../assets/images/bannerImg3.png'

const Banner = ({}) => {
  const handleClick = (e) => {
    e.preventDefault()
    window.location = 'https://app.rentl.io/registracija'
  }

  return (
    <WrapBanner>
      <Content>
        <BannerTextBlock>
          <Title>Što je hotel PMS Software?</Title>
          <Text>
            PMS je kratica za Property Management System, hotelski sustav upravljanja, a koristi ga hotelsko osoblje i
            management za cjelokupno upravljanje poslovanjem hotela.
          </Text>
          <Button onClick={(e) => handleClick(e)}>Zatražite besplatne konzultacije</Button>
        </BannerTextBlock>
        <BannerImageBlock>
          <BannerImage1>
            <img src={bannerImg1} />
          </BannerImage1>
          <BannerImage2>
            <img src={bannerImg2} />
          </BannerImage2>
          <BannerImage3>
            <img src={bannerImg3} />
          </BannerImage3>
        </BannerImageBlock>
      </Content>
    </WrapBanner>
  )
}

export default Banner
