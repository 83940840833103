import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

export const WrapHPMS = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center; */
  box-sizing: border-box;
  max-width: 1860px;
  min-width: 1200px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 1000px) {
    min-width: unset;
    width: 100vw;
  }
  @media only screen and (max-width: 425px) {
  }
`

import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Header from 'components/Header'
import Footer from 'components/Footer'

import Logo from 'components/Logo'
import Button from 'components/Button'

import NotFoundImg from 'images/not-found-404.png'
import Banner from '../components/hotel-property-management-system/banner'
import Content from '../components/hotel-property-management-system/content'
import FAQauthority from '../components/hotel-property-management-system/FAQauthority'
import GetStartedCta from '../components/GetStartedCTA'
import schema from '../components/hotel-property-management-system/schema.json'

const faqData = [
  {
    content:
      'PMS je kratica za Property Management System, hotelski sustav upravljanja, a koristi ga hotelsko osoblje i uprava za cjelokupno upravljanje poslovanjem hotela. Kliknite ovdje kako biste saznali više o hotelskom PMS-u.',
    title: 'Što je hotelski PMS?'
  },
  {
    content:
      'Legacy PMS je zastarjelo rješenje kojima nedostaje intuitivnosti i više mogućnosti prilagobe modernim zahtjevima gostiju. Naslijeđena rješenja obično iziskuju visoke troškove i teža su za implementaciju. Nadogradnje i prilagodbe su skupe i zahtijevaju mnogo vremena kako bi se implementirale, i često podrazumijevaju prekid rada prilikom nadogradnji. Nasuprot naslijeđenim sustavima su cloud-native sustavi. Cloud sustavi su fleksibilniji i jednostavniji za implementaciju.',
    title: 'Koja je razlika između legacy on-premise i cloud-native PMS-a?'
  },
  {
    content:
      'Rentlio je moderan cloud-native sustav namijenjen upravljanju hotela svih veličina. Više od tisuću klijenata u više od 40 zemalja svijeta upravlja svojim poslovanjem koristeći Rentlio Property Management sustav.',
    title: 'Zašto Rentlio PMS?'
  }
]
const cta_mobile_apps = {
  title: 'Želim isprobati Rentlio!  ?',
  description: 'Kreiraj svoj Rentlio račun, pozovi članove tima i uživaj u bržem, efikasnijem i fokusiranijem radu!'
}

const HPMS = ({ location }) => {
  return (
    <Layout locale="hr">
      <SEO
        title="#1 Hotel PMS l Property Management Sustav l Rentlio"
        description="PMS je kratica za Property Management System, hotelski sustav upravljanja, a koristi ga hotelsko osoblje i management za cjelokupno upravljanje poslovanjem hotela."
        pathname={location.pathname}
        lang="hr"
        location={location}
        schemaMarkup={schema}
      />
      <Header mode="dark" locale="hr" color={'#FFFFFF'} opacity={0.3} location={location} hasTranslation="false" />
      <WrapHPMS>
        <Banner />
        <Content locale="hr" />
      </WrapHPMS>
      <FAQauthority questions={faqData} title="FAQ" authority />
      <GetStartedCta data={cta_mobile_apps} locale="en" location={location} />

      <Footer locale="hr" location={location} />
    </Layout>
  )
}

export default HPMS
